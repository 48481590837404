import { useLocation, Route, Redirect } from 'react-router-dom'
import { userService } from '../services/user/userService'
import { useSelector } from 'react-redux'

function PrivateRoute({ component: Component, ...rest }) {
  const location = useLocation();
  const isAuthenticated = userService.getLoginUser()
  const { loggedInGuest } = useSelector((state) => state.userModule)

  return (
    <Route
      {...rest}
      render={(props) =>
        (location.pathname === "/home/share" || isAuthenticated || loggedInGuest === true) ? <Component {...props} /> : <Redirect to="/signin" />
      }
    />
  )
}

export default PrivateRoute
