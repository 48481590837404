import React, { useEffect } from "react";
import { useHistory, useLocation, Route, Redirect } from 'react-router-dom'
import { userService } from '../services/user/userService'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../store/actions/userActions'

function PublicRoute({ component: Component, ...rest }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const isAuthenticated = userService.getLoginUser()
  const { loggedInGuest } = useSelector((state) => state.userModule)

  useEffect(() => {
    if (location.pathname === "/register") {
      dispatch(logout()).then((res) => {
        history.push("/register" + location.search);
      });
    }
  }, [dispatch])

  return (
    <Route
      {...rest}
      render={(props) =>
        (!isAuthenticated && loggedInGuest === false) ? <Component {...props} /> : <Redirect to="/home/main" />
      }
    />
  )
}

export default PublicRoute
